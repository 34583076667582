const AEIcon = "https://imagizer.imageshack.com/v2/320x240q90/924/B7qjb7.png";
const AIIcon = "https://imagizer.imageshack.com/v2/320x240q90/923/WmqTci.png";
const CSSIcon = "https://imagizer.imageshack.com/v2/320x240q90/924/qhlXO2.png";
const ExtendScriptIcon =
  "https://imagizer.imageshack.com/v2/320x240q90/923/wUoMnx.png";
const FigmaIcon =
  "https://imagizer.imageshack.com/v2/320x240q90/923/5opR8k.png";
const HTML5Icon =
  "https://imagizer.imageshack.com/v2/320x240q90/923/RBu9nj.png";
const JSIcon = "https://imagizer.imageshack.com/v2/320x240q90/924/A586q5.png";
const MJMLIcon = "https://imagizer.imageshack.com/v2/320x240q90/924/lH9qxL.png";
const ReactIcon =
  "https://imagizer.imageshack.com/v2/320x240q90/923/3NKwr4.png";
const XDIcon = "https://imagizer.imageshack.com/v2/320x240q90/922/STM7wv.png";

const langImages = {
  AEIcon,
  AIIcon,
  CSSIcon,
  ExtendScriptIcon,
  FigmaIcon,
  HTML5Icon,
  JSIcon,
  MJMLIcon,
  ReactIcon,
  XDIcon,
};

export default langImages;
