// import React from 'react';

//image size should be 1200 × 661

import { vibesEmail } from "../img/projImages.js";

const vibesEmailProject = {
  title: "Tivian Vibes Email Templates",
  shortTitle: "vibesemail",
  description:
    "Modernized internal communication for Tivian's 150+ employees.",
  heroImage: vibesEmail,
  heroAlt: "Email template displayed on a Mac and iPhone.",
  previewImg: vibesEmail,
  previewAlt: "Email template displayed on a Mac and iPhone.",
  skills: ["HTML5", "CSS3", "MJML"],
  introText: [
    {
      index: 0,
      text: "Tivian had a campaign called Vibes, with the goal of regularly capturing employee feedback. I worked with the Design and Engineering teams to modernize the email templates and landing pages for the weekly employee surveys.",
    },
  ],
  imgsParas: [],
  optstyles: "dropshadow",
};

export default vibesEmailProject;
