// import React from 'react';
import {
  previewImg,
} from "../img/projImages.js";

const ScrollingInfoProject = {
  title: "Scrolling Infographic",
  extLink: "/scrollInfo.html",
  extLinkText: "View Live Project",
  description: "An interactive infographic on goal-setting steps.",
  previewImg: previewImg,
  previewAlt:
  'Beginning frame of an infographic, with title text "See an Effective Goal in Action"',
  skills: ["HTML5", "CSS3", "JS/ES6", "AdobeXD"],
  optstyles: "dropshadow",
};

export default ScrollingInfoProject;
