// import React from 'react';

import { dogPage, indivPage } from "../img/projImages.js";

const TheCenterWordpress = {
  title:
    "ShelterLuv Aggregation for Companion Animal Community Center WordPress",
  shortTitle: "caccwp",
  description:
    "A php-based plugin leveraging the Shelterluv API to aggregate animal listings, descriptions, and attributes to CACC WordPress website.",
  heroImage: indivPage,
  heroAlt:
    "Screenshot of a webpage showing images and description of a small dog named Dusty.",
  previewImg: indivPage,
  previewAlt:
    "Screenshot of a webpage showing images and description of a small dog named Dusty.",
  skills: ["HTML5", "CSS3", "JS/ES6", "PHP"],
  introText: [
    {
      index: 0,
      text: "I've volunteered with shelter and foster dogs for several years, and I also provide website support for Companion Animal Community Center (CACC) - a community-based dog and cat foster network. CACC uses WordPress for hosting, and ShelterLuv as a database of our available animals, so I wrote a php plugin to transform the results from the ShelterLuv API into reader-friendly listings.",
    },
  ],
  imgsParas: [
    {
      index: 0,
      img: dogPage,
      alt: "Screenshot of a webpage showing several listings of adoptable dogs.",
      text: "The homepage, as well as the pages for Dogs and Cats, take the results of a call to the ShelterLuv API and present them for potential adopters, with preview images and short descriptions for each animal. The 'Adopt' button leads to an individual animal's page, populated with a query string, and provides more information for the reader. All of this is written as a shortcode plugin, so someone working in the front-end of WordPress can easily drop in the plugin segments and associated styling. I have also written documentation on how to set up and use the plugin.",
    },
  ],
  optstyles: "dropshadow",
  extLink: "https://companionanimalcommunitycenter.org/",
  extLinkText: "View Live Project",
};

export default TheCenterWordpress;
