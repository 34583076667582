// import React from 'react';

import {
  boxUploader,
  voisparkMain,
} from "../img/projImages.js";

const voisparkProject = {
  title: "VS3 (Voice Spark)",
  shortTitle: "vs3",
  description: "An internal voice-over file request & management tool.",
  heroImage: voisparkMain,
  heroAlt: "Screencap of Writer Dashboard on Voice Spark.",
  previewImg: voisparkMain,
  previewAlt: "Screencap of Writer Dashboard on Voice Spark.",
  skills: ["HTML5", "CSS3", "JS/ES6", "React", "Figma", "PHP", "MySQL"],
  introText: [
    {
      index: 0,
      text: "Voice Spark, otherwise known as VS3, is an internal tool used for requesting, previewing, and managing voice-over files. Voice Spark was built to replace a depreciated system, and since launching has successfully handled over 1,000 requests, while managing an overall database of 10,000+ requests. ",
    },
    { index: 1,
      text: "My team also developed a new version of VoiSpark in React and Node.js, leveraging Material UI to improve the user experience. See the Github repo for that version below."
    }
  ],
  imgsParas: [
    {
      index: 0,
      img: boxUploader,
      alt: "Screencap of use of Box uploader UI in Voice Spark.",
      text: "Three types of users access Voice Spark: admins, writers, and voice talents. Writers can make requests, send scripts, and manage due dates and corrections. Previously, one of the slowest processes for writers and voice talents involved uploading and managing files, so we prioritized the automation of these tasks through VS3. The system integrates WorkFront and Box API for efficient, automated project and file management. Native Box UI is embedded in the system so users can easily upload, preview, and download files relevant only to their requests.",
    }
  ],
  optstyles: "dropshadow",
  extLink: "https://github.com/lauraliz6/tv-VSRN",
  extLinkText: "VoiSpark Redesign Repo",
};

export default voisparkProject;
