import images from "../img/langImages";

const Languages = [
  {
    id: 0,
    name: "HTML5",
    shortName: "html",
    img: images.HTML5Icon,
    bImg: images.HTML5Blue,
    selected: false,
    level: 100,
    noneText: "",
  },
  {
    id: 1,
    name: "CSS3",
    shortName: "css",
    img: images.CSSIcon,
    bImg: images.CSSBlue,
    selected: false,
    level: 88,
    noneText: "",
  },
  {
    id: 2,
    name: "JS/ES6",
    shortName: "js",
    img: images.JSIcon,
    bImg: images.JSBlue,
    selected: false,
    level: 80,
    noneText: "",
  },
  {
    id: 3,
    name: "React",
    shortName: "react",
    img: images.ReactIcon,
    selected: false,
    level: 75,
    noneText: "This portfolio website was built with React!",
  },
  {
    id: 4,
    name: "MJML",
    shortName: "mjml",
    img: images.MJMLIcon,
    bImg: images.MJMLBlue,
    selected: false,
    level: 65,
    noneText: "No projects to show here...yet!",
  },
  {
    id: 5,
    name: "ExtendScript",
    shortName: "es",
    img: images.ExtendScriptIcon,
    bImg: images.ExtendScriptBlue,
    selected: false,
    level: 75,
    noneText: "",
  },
  {
    id: 6,
    name: "AfterEffects",
    shortName: "ae",
    img: images.AEIcon,
    bImg: images.AEBlue,
    selected: false,
    level: 100,
    noneText: "",
  },
  {
    id: 7,
    name: "Illustrator",
    shortName: "ai",
    img: images.AIIcon,
    bImg: images.AIBlue,
    selected: false,
    level: 65,
    noneText: "",
  },
  {
    id: 8,
    name: "AdobeXD",
    shortName: "xd",
    img: images.XDIcon,
    bImg: images.XDBlue,
    selected: false,
    level: 60,
    noneText: "",
  },
  {
    id: 9,
    name: "Figma",
    shortName: "figma",
    img: images.FigmaIcon,
    bImg: images.FigmaBlue,
    selected: false,
    level: 55,
    noneText: "",
  },
];

export default Languages;
