import React from 'react';
import { useParams } from 'react-router-dom';

import ProjectPreviews from "../components/ProjectPreviews";

function WorkPage() {
  let lang = useParams();

  return (
    <div>
      <ProjectPreviews filter={lang.lang} />
    </div>
      
  );
}

export default WorkPage;