// import React from 'react';

import {
  addScriptBtn,
  navigateTabs,
  preBuiltTool,
  mSEpreview,
  docking,
} from "../img/projImages.js";

const mSEProject = {
  title: "Mini SparkEffects (mSE)",
  shortTitle: "mSE",
  description:
    "A custom, user-defined internal tool for motion graphics video creation.",
  heroVideo:
    "https://player.vimeo.com/video/531093834?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  heroAlt: "Video preview of mSE functionality",
  previewImg: mSEpreview,
  previewAlt:
    "Screencapture of AfterEffects extension tool with title Mini SparkEffects (mSE).",
  skills: ["ExtendScript", "JS/ES6", "CSS3", "AdobeXD", "AfterEffects"],
  introText: [
    {
      index: 0,
      text: "Mini SparkEffects, or mSE, is an AfterEffects extension built specifically for the Motion Graphics team at GuideSpark. The extension started as a reproduction of a depreciated internal tool primarily focused on project organization, and grew to an extension with features requested by the Motion Graphics team.",
    },
  ],
  imgsParas: [
    {
      index: 0,
      img: preBuiltTool,
      alt: "Screencapture of user initiating spell check tool",
      text: "Our Motion Graphics team works most efficiently when project and asset organization is consistent across the team, so the built-in buttons in mSE are primarily focused on project organization. For instance, the Organize tool sorts assets via a pre-defined folder structure. Other tools, like the Spell Check tool, were added for quality control, and open up in their own windows to preserve their original UI.",
    },
    {
      index: 1,
      img: docking,
      alt: "Screencapture of user docking mSE panel vertically and horizontally.",
      text: "Despite using many of the same Scripts for project creation and organization, each Designer has an individual approach to how they lay out their workspace. Recognizing this, we made the mSE panel dockable both vertically and horizontally, so its thin strip of buttons fits easily within the workspace.",
    },
    {
      index: 2,
      img: navigateTabs,
      alt: "Screencapture of user navigating between tabbed categories in mSE tool",
      text: "We went through several iterations of design, consulting with designers on their workflows, before building the extension. Designer feedback led us to the structure of mSE - buttons are organized into sections and tabs for ease of navigation and use. The user has full control over the naming and organization of these buttons, and can rearrange the order of the sections and buttons to best fit their individual workflow.",
    },
    {
      index: 3,
      img: addScriptBtn,
      alt: "Screencapture of user adding a custom Script button to the mSE tool",
      text: "Most importantly for user customization, new buttons can be added to the panel, which can be linked to Scripts, Presets, or even Expressions. The additional import/export feature allows the user to recover an old layout of buttons, or import a custom layout from another team member.",
    },
  ],
  optstyles: "",
  extLink: "https://github.com/lauraliz6/gs-mSE",
  extLinkText: "View on GitHub",
};

export default mSEProject;
