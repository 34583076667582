// import React from 'react';

//image size should be 1200 × 661
const rgbdleSS = "https://i.ibb.co/74TgjPx/rgbdle.png";

const rgbdleProject = {
  title: "RGBdle",
  extLink: "https://www.rgbdle.page/",
  extLinkText: "View Live Project",
  description: "Like Wordle, but with colors.",
  previewImg: rgbdleSS,
  previewAlt: "Screencapture of rgbdle.page",
  skills: ["React", "JS/ES6", "CSS3", "Figma"],
  optstyles: "dropshadow",
};

export default rgbdleProject;
