// import React from 'react';

//image size should be 1200 × 661

import {
  tvVersion
} from "../img/projImages.js";

const InfoExpProject = {
  title: "Goal-Setting Experience",
  shortTitle: "infoexp",
  description:
    "An interactive experience fusing education and personal goal-setting.",
  heroImage: tvVersion,
  heroAlt:
    "Screencap gif of a webpage that states Oh hey there! Let's talk about goals.' with an arrow button below. The mouse clicks on the arrow, leading to another page animating on.",
  previewImg: tvVersion,
  previewAlt:
    "Screencap of an infographic showing a timeline for goal-setting.",
  skills: ["HTML5", "CSS3", "JS/ES6", "AdobeXD"],
  introText: [
    {
      index: 0,
      text: "Building on the goal-setting steps outlined in a pre-existing infographic, my team created this demo piece as a more immersive, personalized approach to teaching goal-setting. We started with a basic wireframe, then partnered with a member of the Art team to brand the experience.",
    },
  ],
  imgsParas: [
  ],
  extLink: "https://tv-goalsetting.vercel.app/",
  extLinkText: "View Live Project",
};

export default InfoExpProject;
