// import React from 'react';

//image size should be 1200 × 661

import { dmInfoIcon, dmQuestion, dmResult, dmResultDetails, dmStartPage } from "../img/projImages.js";

const decisionMakingToolProject = {
  title: "Decision Making Tool",
  shortTitle: "decisionmaking",
  description:
    "Interactive tool walking users through selecting a medical plan.",
  heroImage: dmStartPage,
  heroAlt: "Survey start page with title 'Find your plan', paragraph of text about choosing a medical plan, illustration of a man at a laptop with a thought bubble containing two medical icons, and a start button.",
  previewImg: dmQuestion,
  previewAlt: "Survey page with question title 'On average, how often do you tend to receive care?', three answer choices, and back and next buttons.",
  skills: ["CSS3", "JS/ES6"],
  introText: [
    {
      index: 0,
      text: "This interactive tool leverages Tivian's survey technology to guide end-users through the process of selecting a medical plan. The brief questionnaire gathers information about the user's typical medical plan needs, and ultimately suggests one of the company's medical plan offerings.",
    },
    {
      index: 1,
      text: "For this project, I worked with the Product team, a Lead Designer, and a medical plan content specialist. This asset was based on an existing video, which we transformed into this more dynamic, personalized experience.",
    },
  ],
  imgsParas: [
    {
      index: 0,
      img: dmInfoIcon,
      alt: "Survey screen with a pop-up informational tag.",
      text: "Each question is accompanied by a brief explanation of why the question is being asked, so that users have a comprehensive understanding of how various factors may influence their medical plan selection."
    },
    {
      index: 1,
      img: dmResult,
      alt: "Survey end screen showing a result of Preferred Provider Organization (PPO), alongside an illustration of a woman, a score placement chart, and some explanatory text.",
      text: "The end result of the tool is a suggestion for which of the company's medical plans the user could consider. This is calculated with Tivian's back-end survey variables, and displayed to the user as a placement between two plan types."
    },
    {
      index: 2,
      img: dmResultDetails,
      alt: "Survey end screen with paragraphs of various aspects to keep in mind when selecting a medical plan type.",
      text: "The result page also shows details of various aspects to keep in mind when selecting a medical plan. At the end of the experience, the user can start over, or return to Tivian's main platform.",
    },
  ],
  optstyles: "dropshadow",
};

export default decisionMakingToolProject;
