//image size should be 1200 × 661
//test comment

//email templates
export const leftAlign =
  "https://imagizer.imageshack.com/img922/6498/3acf5D.png";
export const salesEmail = "https://i.ibb.co/z47LY31/emaillayouts-sales.png";
export const centered =
  "https://imagizer.imageshack.com/img923/9704/p6gXsF.png";
export const topBanner =
  "https://imagizer.imageshack.com/img922/5938/Ds8jEb.png";

//vibes emails
export const vibesEmail = "https://i.ibb.co/JkxfRPJ/vibes-Email-Layouts.png";

//infographic experience
export const tvVersion = "https://i.ibb.co/7J0NQ7J/tv-goalsetting.png";

//motion reel
export const reelPreview =
  "https://imagizer.imageshack.com/img922/8628/HJUZkJ.png";
export const keysInTheCar =
  "https://imagizer.imageshack.com/img924/5038/0havMU.png";
export const mrSquare = "https://imagizer.imageshack.com/img924/340/UHehYw.png";
export const timeOff = "https://imagizer.imageshack.com/img923/1721/kxTIsc.png";
export const goalsTwo =
  "https://imagizer.imageshack.com/img922/8346/GWU0C5.png";

//scrolling infographic
export const firstSecondImg =
  "https://imagizer.imageshack.com/img924/3185/EKC5oy.gif";
export const finalClickImg =
  "https://imagizer.imageshack.com/img922/8760/aqHxYG.gif";
export const menuSelectImg =
  "https://imagizer.imageshack.com/img923/831/dagsHi.gif";
export const menuScrollImg =
  "https://imagizer.imageshack.com/img924/6739/6pZTzW.gif";
export const previewImg =
  "https://imagizer.imageshack.com/img922/3637/BJk7RU.png";

//the Center
export const dogPage = "https://imagizer.imageshack.com/img922/5496/o4o0mU.png";
export const indivPage =
  "https://imagizer.imageshack.com/img923/1543/IZdsP3.png";

//voispark
export const boxUploader =
  "https://imagizer.imageshack.com/img922/4823/cvktdJ.gif";
export const uploadFile =
  "https://imagizer.imageshack.com/img924/742/ygab8A.gif";
export const voiceRequest =
  "https://imagizer.imageshack.com/img922/659/ib0ja4.gif";
export const voisparkMain =
  "https://imagizer.imageshack.com/img922/7515/3kYKxn.png";

//mSE
export const addScriptBtn =
  "https://imagizer.imageshack.com/img924/3574/HdexZ0.gif";
export const navigateTabs =
  "https://imagizer.imageshack.com/img922/573/bDhqSZ.gif";
export const preBuiltTool =
  "https://imagizer.imageshack.com/img923/4055/pku9pe.gif";
export const mSEpreview = "https://i.ibb.co/4FMBXsf/m-SEpreview.png";
export const docking = "https://imagizer.imageshack.com/img924/8738/ree4qB.png";

//portals app
export const portalsImg =
  "https://imagizer.imageshack.com/img924/7326/soN2W3.png";


//decision making tool
export const dmInfoIcon = "https://i.ibb.co/Dzwkwkq/dm-infoicon.png";
export const dmQuestion = "https://i.ibb.co/WkGCNHz/dm-question.png";
export const dmResult = "https://i.ibb.co/zSnHxgM/dm-result.png";
export const dmResultDetails = "https://i.ibb.co/K66Zg3L/dm-resultdetails.png";
export const dmStartPage = "https://i.ibb.co/QC5hqy4/dm-startpage.png";

