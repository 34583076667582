// import React from 'react';

const portfolioSS = "https://i.ibb.co/5j6TZjQ/porfolio-main.png";

const portfolioProject = {
  title: "Personal Portfolio",
  extLink: "https://www.laurabrown.org/",
  extLinkText: "View Live Project",
  description: "This portfolio website was built with React!",
  previewImg: portfolioSS,
  previewAlt: "Screencapture of www.laurabrown.org",
  skills: ["React", "JS/ES6", "CSS3", "Figma"],
  optstyles: "dropshadow",
};

export default portfolioProject;
