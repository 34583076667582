import React, { useState }  from "react";
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";

import Name from "./components/Name";
import Links from "./components/Links";
import LangBar from "./components/LangBar";

import WorkPage from "./pages/WorkPage";
import AboutMePage from "./pages/AboutMePage";

import AllProjects from "./projects/AllProjects";

function App() {

  const [skillsData, setSkillsData] = useState("");

  return (
    <Router>

    <div className="Main">
    <SkipNavLink />
      <div className="Left">
        <Name />
        <Links />
      </div>
      
      <div className="Right">
        <LangBar skills={skillsData}/>
          <SkipNavContent />
          <Route path="/" exact>
            <WorkPage/>
          </Route>
          <Route path="/work/:lang" exact>
            <WorkPage/>
          </Route>
          <Route path="/project/:id" exact>
            <AllProjects passSkillsData={setSkillsData} />
          </Route>
          <Route path="/about" exact component={AboutMePage} />
      </div>

      
    </div>
    </Router>
  );
}

export default App;
