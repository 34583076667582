import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Languages from "./Languages";

import Lang from './Lang';

function LangBar(props) {

    let items = Languages;

    let skills = props.skills;

    const blue = {color: '#3A71CF', setting: 'blue', height: '50px'};
    const teal = {color: '#3C0894', setting: 'teal', height: '250px'};

    const [bgColor, setBgColor] = useState(blue);
    const [curLang, setCurLang] = useState("");
    const [projView, setProjView] = useState(false);

    function GetLocation() {
        const location = useLocation();
        useEffect(() => {
            const currentPath = location.pathname;
            if (currentPath === '/about'){
                setBgColor(teal);

            }
            else {
                setBgColor(blue);
                setIsHovered({id: 'noHover', state: 'false'});
            }
            if (currentPath.includes('work')){
                let current = currentPath.split("/").pop();
                let langs = Languages;
                let findCur = langs.find(lang=>lang.shortName===current).name;
                setCurLang(findCur);
            }
            else {
                setCurLang('');
            }
            if (currentPath.includes('project')){
                setProjView(true);
            }
            else {
                setProjView(false);
            }
        }, [location]);
    }

    GetLocation();

    const [hovered, setIsHovered] = useState({id: 'noHover', state: 'false'});

    function makeItems(items){
        return items.map(item => {
            if (item.name === curLang){
                item.selected = true;
            }
            else {
                item.selected = false;
            }
            if (projView === true){
                if (skills.includes(item.name)){
                    item.selected = true;
                }
                else {
                    item.selected = false;
                }
            }
            return (
            <Lang item = {item} key={item.id} 
            setting={bgColor.setting} hovered={hovered.state} 
            selected={item.selected}/>)
        })
    }

    function handleHover(){
        if (bgColor.setting === 'teal'){
            setIsHovered({id: 'hover', state: 'true'});
        }  
    }

    function handleUnhover(){
        if (bgColor.setting === 'teal'){
            setIsHovered({id: 'noHover', state: 'false'});
        } 
    }

    return(
        <div className="LangBar" id={hovered.id}
            style={{backgroundColor: bgColor.color, maxHeight: bgColor.height}}
            onMouseOver={handleHover} onMouseLeave={handleUnhover}>
            {makeItems(items)}
        </div>      
    )
}

export default LangBar;