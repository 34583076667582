import { NavLink } from "react-router-dom";

function Lang(props) {
  const selected = props.selected;

  const colorSetting = props.setting;

  function setSelect() {
    if (selected === true) {
      return "selected";
    } else {
      return "";
    }
  }

  return (
    <NavLink exact={true} to={`/work/${props.item.shortName}`}>
      <div className={`Lang ${setSelect()}`}>
        <p className={colorSetting}>{props.item.name}</p>
        <img src={props.item.img} key={props.item.img} alt={props.item.name} />
      </div>
    </NavLink>
  );
}

export default Lang;
