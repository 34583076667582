// import React from 'react';

//image size should be 1200 × 661

import {
  reelPreview,
  keysInTheCar,
  mrSquare,
  timeOff,
  goalsTwo,
} from "../img/projImages.js";

const MotionReelProject = {
  title: "Motion Reel",
  shortTitle: "motionreel",
  description:
    "A sampling of motion graphics work produced both for and outside of work.",
  heroVideo:
    "https://player.vimeo.com/video/347222680?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  heroAlt: "Motion graphics reel, hosted on vimeo.",
  previewImg: reelPreview,
  previewAlt:
    "Thumbnail of motion graphics reel, image shows happy block character under a sun.",
  skills: ["Illustrator", "AfterEffects"],
  introText: [
    {
      index: 0,
      text: "This reel is a sampling of the motion graphics work I've produced both for and outside of work.",
    },
  ],
  imgsParas: [
    {
      index: 0,
      img: keysInTheCar,
      alt: "Letter K transformed to look like car keys.",
      text: "Several pieces in the reel were created as part of a 36 Days of Type challenge. I focused on a theme for the challenge – songs by Jukebox the Ghost. The challenge allowed me to explore more advanced expressions in AfterEffects.",
    },
    {
      index: 1,
      img: goalsTwo,
      alt: 'Green ball approaching flag, below text "Short-Term Goals."',
      text: "The clips with the little green ball are from a video produced for work on goal-setting. The video follows the ball character as it explains different types of goals, and how to set and achieve them. This piece was made in collaboration with storyboard artists, art directors, and illustrators. I did all the animation, with mentorship from another animator.",
    },
    {
      index: 2,
      img: timeOff,
      alt: "Character walking on a beach.",
      text: "Clips with character animation are primarily from work, created in collaboration with art directors and illustrators, as well as other animators. I had fun working with more complex character movements, consulting with a colleague who has done advanced character animation.",
    },
    {
      index: 3,
      img: mrSquare,
      alt: 'Square and circle characters next to each other above a line of text that says "Find solutions together."',
      text: "The blue square comes from a style exploration as part of an exercise with the design team. I storyboarded, illustrated, and animated a sample, with a focus on using a simple shape as a dynamic character.",
    },
  ],
  optstyles: "dropshadow",
};

export default MotionReelProject;
