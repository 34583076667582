import React from "react";
import { NavLink } from "react-router-dom";

import { lbLogo } from "../img/otherImgs.js";

function Name() {
  return (
    <div className="Name">
      <NavLink exact={true} to="/">
        <img
          src={lbLogo}
          alt="Laura Brown logo, initials L.B."
          width="auto"
          height="56px"
        />
      </NavLink>
      <h1>Laura Brown</h1>
      <h2>Creative Web Developer</h2>
    </div>
  );
}

export default Name;
