import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { instaIcon, ghIcon, lnIcon } from "../img/otherImgs.js";

function Links() {
  const [isActive, setIsActive] = useState("");

  function GetLocation() {
    const location = useLocation();
    useEffect(() => {
      const currentPath = location.pathname;
      if (currentPath.includes("work") || currentPath.includes("project")) {
        setIsActive("active");
      } else {
        setIsActive("");
      }
    }, [location]);
  }

  GetLocation();

  return (
    <div className="Links">
      <p>
        <NavLink exact={true} to="/" className={`Blue ${isActive}`}>
          Work
        </NavLink>
      </p>
      <p>
        <NavLink exact={true} to="/about" className="Teal">
          About Me
        </NavLink>
      </p>
      <p className="Social">
        <a href="https://www.instagram.com/lauramakesit/">
          <img
            src={instaIcon}
            alt="Instagram Logo"
            width="25px"
            height="25px"
          />
        </a>
        <a href="https://www.linkedin.com/in/laura-brown-898b8a52/">
          <img src={lnIcon} alt="LinkedIn Logo" width="25px" height="25px" />
        </a>
        <a href="https://github.com/lbrowngs">
          <img src={ghIcon} alt="Github Logo" width="25px" height="25px" />
        </a>
      </p>
    </div>
  );
}

export default Links;
