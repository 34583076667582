import React from "react";

const ProfPic = "https://i.ibb.co/6BYVqsx/laurapinky.jpg";

function AboutMePage() {
  return (
    <div className="AboutMe">
      <div className="AboutImg">
        <img src={ProfPic} alt="Laura and dog" />
      </div>
      <div className="AboutText">
        <h3>Hello! I'm Laura, and I love building web apps and tools.</h3>
        <p>
          I'm currently employed as a Web Developer at Tivian, in Redwood City,
          CA. I mostly do front-end design and development, but I also love
          playing with databases, so I've learned PHP, MySQL, and even a little
          Python. When I'm not coding (or thinking about code), I'm probably off
          hiking, playing with shelter dogs, or learning about environmental
          issues.
        </p>
        <a
          href="https://www.linkedin.com/in/laura-brown-898b8a52/"
          target="_blank"
          rel="noreferrer"
        >
          <button>Let's connect on LinkedIn!</button>
        </a>
      </div>
    </div>
  );
}

export default AboutMePage;
