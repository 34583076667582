import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { extLink } from "../img/otherImgs.js";

function ProjPreview(props) {
  const [isShown, setIsShown] = useState(false);
  const [imgOpacity, setOpacity] = useState(1);

  const grow = props.grow;
  let rPadd;
  if (grow === 1) {
    rPadd = 0;
  }

  function handleHover(e) {
    setIsShown(true);
    setOpacity(0.1);
  }

  function handleMouseOut(e) {
    setIsShown(false);
    setOpacity(1);
  }

  return (
    <div
      className="ProjPrev"
      onMouseOver={handleHover}
      onMouseLeave={handleMouseOut}
      style={{ flexGrow: grow, paddingRight: rPadd }}
    >
      {props.project.shortTitle ? (
        <NavLink
          onFocus={handleHover}
          to={`/project/${props.project.shortTitle}`}
        >
          <img
            className={props.project.optstyles}
            src={props.project.previewImg}
            alt={props.project.previewAlt}
            style={{ opacity: imgOpacity }}
          />
        </NavLink>
      ) : (
        <a href={props.project.extLink}>
          <img
            className={props.project.optstyles}
            src={props.project.previewImg}
            alt={props.project.previewAlt}
            style={{ opacity: imgOpacity }}
          />
        </a>
      )}
      {isShown && (
        <div className="ProjPrevText">
          <h3>{props.project.title}</h3>
          <p>{props.project.description}</p>
          {props.project.shortTitle ? (
            <NavLink to={`/project/${props.project.shortTitle}`}>
              <button onBlur={handleMouseOut}>View Project</button>
            </NavLink>
          ) : (
            <a href={props.project.extLink}>
              <button onBlur={handleMouseOut}>
                View Project
                <img
                  src={extLink}
                  style={{ height: 15, width: 15, paddingLeft: 5 }}
                  alt="External Link Icon by shashank singh from NounProject.com"
                ></img>
              </button>
            </a>
          )}
        </div>
      )}
    </div>
  );
}

export default ProjPreview;
